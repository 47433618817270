import React, { Component } from "react";


import hero from "../assets/chat.png";



class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5">
        <h2 className="my-5 text-center">Safe, secure and private AI for schools.</h2>
        <center><img src={hero} alt="CurricuLLM and a student interacting to understand the physics concept of hot air rising." width="628" /></center>
      </div>
    );
  }
}

export default Content;
